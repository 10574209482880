<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState, mapGetters} from "vuex";

import simplebar from "simplebar-vue";

import {with_acrf_token} from "@/methods";


export default {
  page: {
    title: "Support",
    meta: [{ name: "description", content: appConfig.description }]
  },
  computed: {

  },
  components: {
    Layout,
    SupportTicket: () => import('@/components/lazy/support/SupportTicket'),
  },
  methods: {

  },
  created() {

  },
  data() {
    return {

    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Support
        <router-link to="/support" class="small ml-2">
          <i class="fas fa-backspace"></i> Go back
        </router-link>
      </h4>
      <div class="page-title-right bv-d-xs-down-none"><ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            <span class="text-danger text-uppercase">
              <i class="fad fa-clipboard-user" />
              CFTools Internal
            </span>
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span aria-current="location">
            Support
          </span>
        </li>
        <li class="breadcrumb-item">
          <span aria-current="location">
            Read
          </span>
        </li>
      </ol>
      </div>
    </div>
    <SupportTicket :ticket_id="$route.params.ticket_id"></SupportTicket>
  </Layout>
</template>